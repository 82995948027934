import Api from './Api';

export function listSuppliers(params) {
  const exec = `
    query suppliers {
      suppliers {
        id
        name
        services
        contract
        pricesDefaultMode
        pricesDefaultValue
        manager {
          name
          email
        }
      }
    }
  `;

  return {
    fetcher: Api({ authorized: true }).request(exec, params)
  };
}

export function showSupplier({ supplierId }) {
  const exec = `
    query supplier(
      $supplierId: ID!
    ) {
      supplier (
        id: $supplierId
      ) {
        id
        services
        name
        kind
        cnpj
        businessName
        phone
        addressStreet
        addressNumber
        addressComplement
        addressDistrict
        addressZipCode
        addressUf
        addressCity
        contract
        pricesDefaultMode
        pricesDefaultValue
        services
        owner {
          name
          email
        }
        manager {
          name
          email
        }
      }
    }
  `;

  return {
    fetcher: Api({ authorized: true }).request(exec, { supplierId })
  };
}

export function updateSupplier(params) {
  const exec = `
    mutation updateSupplier (
      $id: ID
      $pricesDefaultMode: String
      $pricesDefaultValue: Int
      $services: [String]
    ) {
      updateSupplier (
        id: $id
        pricesDefaultMode: $pricesDefaultMode
        pricesDefaultValue: $pricesDefaultValue
        services: $services
      ) {
        id
        services
        name
        kind
        cnpj
        businessName
        phone
        addressStreet
        addressNumber
        addressComplement
        addressDistrict
        addressZipCode
        addressUf
        addressCity
        contract
        pricesDefaultMode
        pricesDefaultValue
        services
      }
    }
  `;

  return {
    fetcher: Api({ authorized: true }).request(exec, params)
  };
}

export function createSupplier(params) {
  const exec = `
    mutation createSupplier (
      $services: [String]!
      $cnpj: String!
      $name: String!
      $phone: String!
      $invitationEmail: String!
      $businessName: String!
      $cityId: ID!
      $addressStreet: String!
      $addressNumber: String!
      $addressComplement: String
      $addressZipCode: String!
      $addressDistrict: String!
      $addressUf: String!
    ) {
      createSupplier (
        services: $services
        kind: "pj"
        cnpj: $cnpj
        name: $name
        phone: $phone
        businessName: $businessName
        addressStreet: $addressStreet
        addressNumber: $addressNumber
        addressComplement: $addressComplement
        addressZipCode: $addressZipCode
        addressDistrict: $addressDistrict
        addressUf: $addressUf
        cityId: $cityId
        pricesDefaultMode: "markup"
        pricesDefaultValue: 138
        invitationEmail: $invitationEmail
      ) {
        id
      }
    }
  `;

  return {
    fetcher: Api({ authorized: true }).request(exec, params)
  };
}
