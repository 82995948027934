import Api from './Api';

export function listReviews(params) {
  const exec = `
    query(
      $query: String
      $first: Int
      $after: String
      $orderBy: String
      $status: String
      $timeline: String
    ) {
      reviews (
        orderBy: $orderBy
        first: $first
        after: $after
        query: $query
        status: $status
        timeline: $timeline
      ) {
        total
        pageInfo {
          startCursor
          endCursor
          hasNextPage
        }
        edges {
          cursor
          node {
            id
            userId
            rate
            publicComments
            approved
            createdAt
            order {
              id
              checkin
              experience {
                id
                publicTitle
              }
            }
          }
        }
      }
    }
  `;

  return {
    fetcher: Api({ authorized: true }).request(exec, params)
  };
}

export function approveReview(params) {
  const exec = `
    mutation($id: ID!) {
      approveReview(id: $id) {
        id
      }
    }
  `;

  return {
    fetcher: Api({ authorized: true }).request(exec, params)
  };
}

export function reproveReview(params) {
  const exec = `
    mutation($id: ID!) {
      reproveReview(id: $id) {
        id
      }
    }
  `;

  return {
    fetcher: Api({ authorized: true }).request(exec, params)
  };
}
