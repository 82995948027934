import React from 'react';
import { useParams } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Icon from '@material-ui/core/Icon';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { showSupplier, updateSupplier } from '../api/Suppliers';
import Alert from '../util/Alerts.jsx';

const useStyles = makeStyles((theme) => ({
  form: {
    padding: theme.spacing(2),

    '& .MuiFormControl-root': {
      marginBottom: theme.spacing(2),
    }
  }
}));

export default function Supplier() {
  const classes = useStyles();
  let { supplierId } = useParams();
  const [supplier, setSupplier] = React.useState();
  const [pricesDefaultMode, setPricesDefaultMode] = React.useState();
  const [pricesDefaultValue, setPricesDefaultValue] = React.useState();
  const [services, setServices] = React.useState([]);
  const [loading, setLoading] = React.useState();
  const [notice, setNotice] = React.useState();
  const [error, setError] = React.useState();

  const textAreaRef = React.useRef(null);

  function copyToClipboard(e) {
    textAreaRef.current.select();
    document.execCommand('copy');
    // This is just personal preference.
    // I prefer to not show the whole text area selected.
    e.target.focus();
  };

  const handleUpdateSupplier = () => {
    setLoading(true);

    const { fetcher } = updateSupplier({
      id: supplier.id,
      pricesDefaultMode: pricesDefaultMode || supplier.pricesDefaultMode,
      pricesDefaultValue: parseInt(pricesDefaultValue || supplier.pricesDefaultValue),
      services: services,
    });

    fetcher.then(result => {
      setSupplier(result.updateSupplier);
      setLoading(false);
      setNotice('Atualizado com sucesso');
      setError(null);
    }).catch(err => {
      console.log(err);
      setLoading(false);
      setError('Erro ao salvar');
      setNotice(null);
    });
  };

  React.useEffect(() => {
    if (supplierId) {
      const { fetcher } = showSupplier({
        supplierId: supplierId
      });

      fetcher.then(result => {
        setSupplier(result.supplier);
        setServices(result.supplier.services);
      });
    }
  }, [supplierId]);

  if (!supplier || loading) return <CircularProgress />;

  return (
    <>
      <Alert message={notice || error} error={!!error} />

      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableBody>
            {
              (!supplier.contract || supplier.contract === '')
              &&
              <TableRow hover>
                <TableCell>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="standard-adornment-password">Link de cadastro / aceite</InputLabel>
                    <OutlinedInput
                      inputRef={textAreaRef}
                      readOnly
                      multiline
                      labelWidth={185}
                      value={`https://parceiro.guiapass.com/acceptance/${supplier.id}`}
                      onFocus={copyToClipboard}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={copyToClipboard}
                            onMouseDown={copyToClipboard}
                          >
                            <Icon>file_copy</Icon>
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </TableCell>
              </TableRow>
            }

            <TableRow hover>
              <TableCell><b>Nome</b>: {supplier.name}</TableCell>
            </TableRow>
            <TableRow hover>
              <TableCell><b>Serviços</b>: {supplier.services.join(', ')}</TableCell>
            </TableRow>
            <TableRow hover>
              <TableCell><b>CNPJ</b>: {supplier.cnpj}</TableCell>
            </TableRow>
            <TableRow hover>
              <TableCell><b>Usuário Master</b>: {supplier.owner?.email || '-- sem usuário'}</TableCell>
            </TableRow>
            <TableRow hover>
              <TableCell><b>Gerente</b>: {supplier.manager?.name || '-'}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <br />

      <Paper className={classes.form}>
        <TextField
          fullWidth
          select
          label="Preço final"
          defaultValue={supplier.pricesDefaultMode}
          onChange={e => setPricesDefaultMode(e.target.value)}
          helperText="Modelo de precificação final (valor padrão)"
          variant="outlined"
        >
          <MenuItem value="markup">Markup</MenuItem>
          <MenuItem value="comission">Comissão</MenuItem>
        </TextField>

        <TextField
          fullWidth
          label="Preço final"
          defaultValue={pricesDefaultValue || supplier.pricesDefaultValue}
          onChange={e => setPricesDefaultValue(e.target.value)}
          helperText="Exemplo: Markup 1,333 = 1333. Comissão 25% = 25000"
          variant="outlined"
        />

        <Typography variant="h6">Serviços habilitados</Typography>

        {['tour', 'ticket', 'transfer'].map(service => (
          <FormControlLabel
            key={service}
            control={
              <Checkbox
                checked={services.includes(service)}
                onChange={e => setServices(services.includes(service) ? services.filter(s => s !== service) : [...services, service])}
              />
            }
            label={service.charAt(0).toUpperCase() + service.slice(1)}
          />
        ))}

        <Button
          style={{ marginTop: 16 }}
          onClick={handleUpdateSupplier}
          fullWidth
          variant="contained"
          color="primary"
          size="large"
        >
          Salvar
        </Button>
      </Paper>
    </>
  )
}
