import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link as RouterLink, useSearchParams } from "react-router-dom";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import Switch from '@material-ui/core/Switch';
import { listReviews, approveReview, reproveReview } from '../api/Reviews';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  filters: {
    '& .MuiGrid-item': {
      paddingRight: theme.spacing(2),

      '&:last-of-type': {
        padding: 0,
      }
    }
  },
}));

export default function Reviews() {
  const [searchParams, setSearchParams] = useSearchParams();
  const page = parseInt(searchParams.get('page') || 1);
  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [reviews, setReviews] = React.useState();
  const [loading, setLoading] = React.useState();
  const [loadingApprove, setLoadingApprove] = React.useState(false);

  const setPage = (newPage) => {
    setSearchParams({ page: newPage });
  };

  const loadReviews = ({ noLoading = false } = {}) => {
    if (!noLoading) setLoading(true);

    const { fetcher } = listReviews({
      first: rowsPerPage,
      after: String(rowsPerPage * (page - 1)),
    });

    fetcher.then(result => {
      if (!noLoading) setLoading(false);
      setReviews(result.reviews);
    });
  };

  const handleApprove = (id) => {
    setLoadingApprove(true);

    const { fetcher } = approveReview({ id });
    fetcher.then(result => {
      setLoadingApprove(false);
      loadReviews({ noLoading: true });
    });
  };

  const handleReprove = (id) => {
    setLoadingApprove(true);

    const { fetcher } = reproveReview({ id });
    fetcher.then(result => {
      setLoadingApprove(false);
      loadReviews({ noLoading: true });
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  React.useEffect(() => {
    loadReviews();
  }, [searchParams, rowsPerPage]);

  if (!reviews || loading) return <CircularProgress />;

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <TableHead>
              <TableRow>
                <TableCell>Reserva</TableCell>
                <TableCell>Criado em</TableCell>
                <TableCell>Nota</TableCell>
                <TableCell>Serviço</TableCell>
                <TableCell>Comentário</TableCell>
                <TableCell>Aprovado</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {reviews.edges.map(o => o.node).map(review => (
                <TableRow
                  hover
                  tabIndex={-1}
                  key={review.id}
                >
                  <TableCell component="th" scope="row">
                    <RouterLink to={`/orders/${review.order.id}`}>{review.order.id}</RouterLink>
                  </TableCell>
                  <TableCell>{Intl.DateTimeFormat('pt-BR').format(new Date(review.createdAt))}</TableCell>
                  <TableCell style={{ color: '#ffb400' }}>
                    {Array(review.rate).fill('★').join('')}
                    {Array(5 - review.rate).fill('☆').join('')}
                  </TableCell>
                  <TableCell>{review.order.experience.publicTitle}</TableCell>
                  <TableCell>{review.publicComments}</TableCell>
                  <TableCell>
                    <Switch
                      checked={review.approved}
                      onChange={() => review.approved ? handleReprove(review.id) : handleApprove(review.id)}
                      disabled={loadingApprove}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={reviews.total}
          rowsPerPage={rowsPerPage}
          page={page === 1 ? 0 : page - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
