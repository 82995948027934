import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';

import Login from './components/Login';
import Suppliers from './components/Suppliers';
import Supplier from './components/Supplier';
import NewSupplier from './components/NewSupplier';
import Auth from './components/Auth';
import User from './components/User';
import Products from './components/Products';
import Contracts from './components/Contracts';
import Contract from './components/Contract';
import NewContract from './components/NewContract';
import Product from './components/Product';
import Orders from './components/Orders';
import Order from './components/Order';
import Reviews from './components/Reviews';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Navigate,
  useLocation
} from "react-router-dom";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: 'auto',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

function Layout() {
  const classes = useStyles();
  const { user } = React.useContext(User);

  if (!user) return <></>;

  return (
    <Router>
      <div className={classes.root}>
        <CssBaseline />
        {
          (user && user.token)
          &&
          <>
            <AppBar position="fixed" className={classes.appBar}>
              <Toolbar>
                <Typography variant="h6" noWrap>
                  GuiaPass Admin
                </Typography>
              </Toolbar>
            </AppBar>
            <Drawer
              className={classes.drawer}
              variant="permanent"
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              <Toolbar />
              <div className={classes.drawerContainer}>
                <List>
                  {
                    user.roles.includes('reviewer')
                    &&
                    <ListItem button component={Link} to="/suppliers">
                      <ListItemIcon><Icon>business</Icon></ListItemIcon>
                      <ListItemText primary="Parceiros" />
                    </ListItem>
                  }

                  {
                    user.roles.includes('orders')
                    &&
                    <ListItem button component={Link} to="/orders">
                      <ListItemIcon><Icon>file_copy</Icon></ListItemIcon>
                      <ListItemText primary="Reservas" />
                    </ListItem>
                  }

                  {
                    user.roles.includes('reviewer')
                    &&
                    <ListItem button component={Link} to="/suppliers/new">
                      <ListItemIcon><Icon>business</Icon></ListItemIcon>
                      <ListItemText primary="Novo parceiro" />
                    </ListItem>
                  }

                  {
                    user.roles.includes('reviewer')
                    &&
                    <ListItem button component={Link} to="/reviews">
                      <ListItemIcon><Icon>star</Icon></ListItemIcon>
                      <ListItemText primary="Avaliações" />
                    </ListItem>
                  }
                </List>
              </div>
            </Drawer>
          </>
        }

        <main className={classes.content}>
          <Toolbar />

          <Routes>
            <Route path="/suppliers/new" element={
              <RequireAuth>
                <NewSupplier />
              </RequireAuth>
            } />
            <Route path="/suppliers/:supplierId/products/:productId" element={
              <RequireAuth>
                <Product />
              </RequireAuth>
            } />
            <Route path="/suppliers/:supplierId/products" element={
              <RequireAuth>
                <Products />
              </RequireAuth>
            } />
            <Route path="/suppliers/:supplierId/contracts/new" element={
              <RequireAuth>
                <NewContract />
              </RequireAuth>
            } />
            <Route path="/suppliers/:supplierId/contracts/:id" element={
              <RequireAuth>
                <Contract />
              </RequireAuth>
            } />
            <Route path="/suppliers/:supplierId/contracts" element={
              <RequireAuth>
                <Contracts />
              </RequireAuth>
            } />
            <Route path="/suppliers/:supplierId" element={
              <RequireAuth>
                <Supplier />
              </RequireAuth>
            } />
            <Route path="/suppliers" element={
              <RequireAuth>
                <Suppliers />
              </RequireAuth>
            } />
            <Route path="/orders/:orderId" element={
              <RequireAuth>
                <Order />
              </RequireAuth>
            } />
            <Route path="/orders" element={
              <RequireAuth>
                <Orders />
              </RequireAuth>
            } />
            <Route path="/reviews" element={
              <RequireAuth>
                <Reviews />
              </RequireAuth>
            } />
            <Route path="/" element={<Login />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default function App() {
  return (
    <Auth>
      <Layout />
    </Auth>
  );
}

// A wrapper component that checks for authentication
// and redirects to the login screen if not authenticated
function RequireAuth({ children }) {
  const { user } = React.useContext(User);
  const location = useLocation();

  if (!user) return null;

  if (!(user && user.token)) {
    // Redirect them to the login page with a return to where they came from
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
}
